import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty

function PlayersRow3() {
  return (
        <section className="players_row_3">
        <div data-hook="bgLayers" className="_3wnIc">
          <div className="_3KzuS _3SQN-"></div>
          <div className="_2GUhU"></div>
        </div>
        <div data-testid="columns" className="_1uldx">
          <div id="comp-l7jyqfrt" className="_1vNJf">
            <div className="_3wnIc">
              <div data-testid="colorUnderlay" className="_3KzuS _3SQN-"></div>
              <div id="bgMedia_comp-l7jyqfrt" className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_70807b976e624b5f9fb599777ae0e4b3~mv2.jpg/v1/fill/w_475,h_429,al_c,lg_1,q_80,enc_auto/cf6500_70807b976e624b5f9fb599777ae0e4b3~mv2.jpg" alt="Ferraro-1238794919-594x594.jpg" className="player-img2" />
              </div>
            </div>
            <div className="player player9">
              <div id="comp-l7jyqfrx2" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                  <span className="font30">
                    <span className="font25">
                      <span className="spacingNormal">
                      <span style={{color:'#FFFFFF'}}>Mario Ferarro</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <div id="comp-l7jyqfs0" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0 font_1">
                  <span className="spacingNormal">
                    <span style={{color:'#FFFFFF'}}>
                      <span className="font1">
                        <span className="font31">SAN JOSE</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div id="comp-l7jyqfs2" className="_1vNJf">
            <div id="bgLayers_comp-l7jyqfs2" data-hook="bgLayers" className="_3wnIc">
              <div data-testid="colorUnderlay" className="_3KzuS _3SQN-"></div>
              <div id="bgMedia_comp-l7jyqfs2" className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_4e96e95b40554640a6db789dfb3d308f~mv2.jpg/v1/fill/w_1281,h_1155,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cf6500_4e96e95b40554640a6db789dfb3d308f~mv2.jpg" alt="" className="player-img2" />
              </div>
            </div>
            <div className="player player10">
              <div id="comp-l7jyqfs6" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0 font_1">
                  <span className="font32">
                    <span className="font25">
                      <span className="spacingNormal">
                        <span style={{color:'#FFFFFF'}}>Erik Gustafsson</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <div id="comp-l7jyqfs8" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                  <span className="spacingNormal">
                    <span style={{color:'#FFFFFF'}}>
                      <span className="font_1">
                        <span className="font34">WASHINGTON</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div id="comp-l7jyqfsa" className="_1vNJf">
            <div id="bgLayers_comp-l7jyqfsa" data-hook="bgLayers" className="_3wnIc">
              <div data-testid="colorUnderlay" className="_3KzuS _3SQN-"></div>
              <div id="bgMedia_comp-l7jyqfsa" className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_b21d2237ade442fca2434a68dd81faee~mv2.jpeg/v1/fill/w_998,h_900,al_c,q_85,enc_auto/cf6500_b21d2237ade442fca2434a68dd81faee~mv2.jpeg" alt="sabres-prospect-rasmus-asplund-loaned-vasteras-ik.jpeg" className="player-img2" />
              </div>
            </div>
            <div className="player player11">
              <div id="comp-l7jyqfsj" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0 font25">
                  <span className="font35">
                    <span className="font25">
                      <span className="spacingNormal">
                        <span style={{color:'#FFFFFF'}}>Rasmus Asplund</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <div id="comp-l7jyqfsl" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                  <span className="spacingNormal">
                    <span style={{color:'#FFFFFF'}}>
                      <span className="font_1">
                        <span className="font36">BUFFALO</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default PlayersRow3
