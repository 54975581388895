import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty

function FaqComponent() {
  return (
    <section className="relative bg-none lg:bg-faq-mint bg-fixed bg-cover bg-center bg-no-repeat -mt-52 sm:-mt-5 md:-mt-10 lg:-mt-52 xl:-mt-32 w-full pb-10 lg:py-60 min-h-1443px">
      <div className="absolute -top-20 left-0 right-0 bg-brush-mint bg-100%-100% bg-no-repeat h-24 w-full">&nbsp;</div>
      <div className="bg-faq-mobile lg:hidden bg-cover bg-top bg-no-repeat w-full min-h-800px">&nbsp;</div>
      <div className="container mx-auto -mt-60 lg:mt-0">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 xl:gap-20">
          <div>&nbsp;</div>
          <div className="flex flex-col items-start col-span-1">
            <div className="flex flex-col items-start justify-start">
              <h2 className="mb-6 prose text-4xl lg:text-5xl xl:text-7xl text-white uppercase font-Bebas-Neue-Pro-Regular">FREQUENTLY ASK <span className="text-primary">QUESTIONS</span>
              </h2>
              <div className="flex items-center justify-start space-x-3">
                <span className="bg-primary w-60 xl:ml-20 h-1"></span>
                <span className="bg-white w-10 h-1"></span>
              </div>
            </div>
            <div className="flex flex-col w-full mt-20" id="collapse">

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-one">WHAT IS SINBIN CLUB?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-one" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                          <i>We are a brand. We are a platform. We are leaders.</i>
                        </b>
                      </p>
                      <p>
                        <b>
                          <i>The Ultimate Investor Illuminati.</i>
                        </b>
                      </p>
                      <p> Access & Proximity to the Top Network of Investors, Resources & Exclusive Opportunities comprised of high-level Traders, VC’s, Web3 Natives, Business Owners, Investors & Winners alike. Unparalleled Day 1 Utility. Future of NFTs. </p>
                      <p>
                        <b>
                          <i> On a mission to Re-Invent NFTs for a more sustainable future with new concepts & much-needed business fundamentals. </i>
                        </b>{' '}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-2">HOW DOES IT WORK?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-2" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                          When you ‘mint’ a SINBIN Club NFT (called a ‘SINBIN Club Pass’), you become part of SINBIN Club. The NFT (SINBIN Club Pass) gives you access to all the benefits of the SINBIN Club
                        </b>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-3">WHAT BENEFITS DO I GET IF l BECOME PART OF SINBIN CLUB BY MINTING AN NFT?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-3" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        Benefits include access to NHL players through digital and in real life (IRL) events, prizes, giveaways and much more. Prizes include tickets and autographed items. Digital events include Discord AMA sessions. IRL events include VIP access/meet & greet at key NHL events such as Winter Classic or Colorado Avalanche Stanley Cup banner raising.
                        </b>
                      </p>
                      <p>
                        <b>
                        SINBIN Club Pass also gives you access to the SINBIN Club House in the Sandbox metaverse.
                        </b>
                      </p>
                    </div>
                  </div>
                </div>              

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-4">DO I NEED TO OWN CRYPTOCURRENCY OR BE AN NFT EXPERT?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-4" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        No. First and foremost, SINBIN Club is a community of everyday, die-hard hockey fans - so we are removing all the typical roadblocks to owning NFTs. We are working with some of the best developers in the business to ensure our fans can buy our NFTs simply by using a credit card (just like any other online transaction). Of course, if you do own crypto currency and prefer to use crypto, we welcome that as well! No prior crypto/NFT/Web3 knowledge or expertise is needed.
                        </b>
                      </p>
                    </div>
                  </div>
                </div>  

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-5">WHAT IS SANDBOX?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-5" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        Sandbox is the leading blockchain based metaverse. Some of the biggest brands and celebrities have purchased land in Sandbox metaverse and are building out their spaces. If you watched this year’s MTV Video Music Awards then you saw Snoop Dogg and Eminem (both NFT and Sandbox owners) perform in the metaverse. We see this as the future of sports and entertainment.
                        </b>
                      </p>
                    </div>
                  </div>
                </div>  

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-6">WHAT WILL SINBIN CLUB HAVE IN THE SANDBOX METAVERSE?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-6" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        SINBIN Club has already purchased land in the Sandbox metaverse and is working with Landvault, one of the top metaverse developers, to build the SINBIN Club House. The SINBIN Club House will feature spaces for our members to hang out in the metaverse, attend special events and even play mini-hockey.
                        </b>
                      </p>
                    </div>
                  </div>
                </div>  

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-7">WHEN CAN I MINT A SINBIN CLUB PASS?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-7" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        We plan to mint before the October 7, 2022 NHL season launch. SINBIN is based on the Ethereum block chain. And, with Ethereum unveiling the much-awaited, cutting-edge Ethereum 2.0 soon, we would like to wait until the Ethereum 2.0 is complete before our first mint so that we are bringing our members the latest and best possible experience.
                        </b>
                      </p>
                    </div>
                  </div>
                </div> 

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-8">WHICH PLAYERS ARE PART OF THE SINBIN CLUB?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-8" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        Currently, just about all of the players represented by Alterno Management are part of the SINBIN Club. Over time, through partnerships and collaborations, our hope is to bring in more and more players across the NHL so that we are giving our members as much access to the NHL as possible.
                        </b>
                      </p>
                    </div>
                  </div>
                </div> 

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-9">WHICH PLAYERS ARE PART OF THE SINBIN CLUB?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-9" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        Currently, just about all of the players represented by Alterno Management are part of the SINBIN Club. Over time, through partnerships and collaborations, our hope is to bring in more and more players across the NHL so that we are giving our members as much access to the NHL as possible.
                        </b>
                      </p>
                    </div>
                  </div>
                </div> 

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-10">WHAT’S THE FUTURE OF THE NFT/CRYPTO/WEB3?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-10" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        Some of the biggest brands and businesses are betting that block chain technology is not only here to stay, it is the way of the future. NHL itself announced a major deal with Sweet recently while Tom Brady joined Autograph.IO for a venture that is very similar to the SINBIN Club (but obviously meant for Brady/football fans).
                        </b>
                      </p>
                    </div>
                  </div>
                </div> 

                <div className="tab2">
                  <div className="bg-faq-o transition-all duration-100 ease-in-out w-full mb-6 bg-no-repeat bg-100%-100% bg-center flex justify-between items-center cursor-pointer">
                    <label className="ml-4 my-5 font-bold text-xl lg:text-2xl font-Bebas-Neue-Pro-Bold text-primary cursor-pointer" htmlFor="tab-single-11">WHAT’S IN IT FOR THE PLAYERS?</label>
                  </div>
                  <input className="absolute opacity-0" id="tab-single-11" type="checkbox" name="tabs" />
                  <div className="tab-content leading-normal  overflow-hidden">
                    <hr className="border-gray-200 dark:border-gray-700" />
                    <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                      <p>
                        <b>
                        Alterno Management is committed to being on the forefront of technology and opportunities for its clients. The SINBIN Club is a way to introduce Alterno Management players to the latest Web3 has to offer, to have players engage with fans like never before and to build a community of players and fans that doesn’t currently exist in hockey or any other sport for that matter.
                        </b>
                      </p>
                    </div>
                  </div>
                </div> 

            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqComponent
