import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty

function PlayersRow2() {
  return (
        <section className="players_row_2">
        <div className="_3wnIc">
          <div className="_3KzuS _3SQN-"></div>
          <div className="_2GUhU"></div>
        </div>
        <div className="_1uldx">
          <div id="comp-l7jybryn" className="_1vNJf">
            <div className="_3wnIc">
              <div data-testid="colorUnderlay" className="_3KzuS _3SQN-"></div>
              <div id="bgMedia_comp-l7jybryn" className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_e4ea3a754c5d42bf82e86d49593ecbec~mv2.png/v1/fill/w_935,h_1066,al_c,q_90,enc_auto/cf6500_e4ea3a754c5d42bf82e86d49593ecbec~mv2.png" alt="Mattias.webp" className="player-img" />
              </div>
            </div>
            <div className="player player5">
              <div className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                  <span className="font12">
                    <span className="font25">
                      <span className="spacingNormal">
                      <span style={{color:'#FFFFFF'}}>Mattias Janmark</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <div className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                  <span className="spacingNormal">
                    <span style={{color:'#FFFFFF'}}>
                      <span className="font_1">
                        <span className="font13">EDMONTON</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div id="comp-l7jybrzb1" className="_1vNJf">
            <div id="bgLayers_comp-l7jybrzb1" data-hook="bgLayers" className="_3wnIc">
              <div data-testid="colorUnderlay" className="_3KzuS _3SQN-"></div>
              <div id="bgMedia_comp-l7jybrzb1" className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_28961019869e444aba8632db794f8c04~mv2.jpeg/v1/fill/w_960,h_1095,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cf6500_28961019869e444aba8632db794f8c04~mv2.jpeg" alt="Isac.jpeg" className="player-img" />
              </div>
            </div>
            <div className="player player6">
              <div id="comp-l7jybrzf2" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                  <span className="font15">
                    <span className="font25">
                      <span className="spacingNormal">
                        <span style={{color:'#FFFFFF'}}>Isac Lundeström</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <div id="comp-l7jybrzh1" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0 font_1">
                  <span className="spacingNormal">
                    <span style={{color:'#FFFFFF'}}>
                      <span className="font_1">
                        <span className="font16">ANAHEIM</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div id="comp-l7jybrzj1" className="_1vNJf">
            <div id="bgLayers_comp-l7jybrzj1" data-hook="bgLayers" className="_3wnIc">
              <div data-testid="colorUnderlay" className="_3KzuS _3SQN-"></div>
              <div id="bgMedia_comp-l7jybrzj1" className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_982d34adfafd40c1b7ad8da95cdb7c94~mv2.jpg/v1/fill/w_475,h_542,al_c,lg_1,q_80,enc_auto/cf6500_982d34adfafd40c1b7ad8da95cdb7c94~mv2.jpg" alt="Carlsson-1366725180-594x594.jpg" className="player-img" />
              </div>
            </div>
            <div className="player player7">
              <div id="comp-l7jybrzn2" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0 font25">
                  <span className='font18'>
                    <span className="font25">
                      <span className="spacingNormal">
                        <span style={{color:'#FFFFFF'}}>Lucas Carlsson</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <div id="comp-l7jybrzp1" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0 font_1">
                  <span className="spacingNormal">
                    <span style={{color:'#FFFFFF'}}>
                      <span className="font_1">
                        <span className="font19">FLORIDA</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div id="comp-l7jybrzr1" className="_1vNJf">
            <div id="bgLayers_comp-l7jybrzr1" data-hook="bgLayers" className="_3wnIc">
              <div data-testid="colorUnderlay" className="_3KzuS _3SQN-"></div>
              <div id="bgMedia_comp-l7jybrzr1" className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_008a7ef5a9c24131a8b67aa0361d3354~mv2.jpg/v1/fill/w_475,h_542,al_c,lg_1,q_80,enc_auto/cf6500_008a7ef5a9c24131a8b67aa0361d3354~mv2.jpg" alt="BoqvistA-1389730063-594x594.jpg" className="player-img" />
              </div>
            </div>
            <div className="player player8">
              <div className="_2Hij5 _3bcaz">
                <p className="font_0 font25">
                  <span className="font21">
                    <span className="font25">
                      <span className="spacingNormal">
                      <span style={{color:'#FFFFFF'}}>Adam Boqvist</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <div id="comp-l7jybrzx" className="_2Hij5 _3bcaz" data-testid="richTextElement">
                <p className="font_0 font_1">
                  <span className="spacingNormal">
                    <span style={{color:'#FFFFFF'}}>
                      <span style={{fontSize:'17px'}}>
                        <span className='font22'>COLUMBUS</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default PlayersRow2
