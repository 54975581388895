import './App.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty
import React, { useState, useEffect } from 'react'
import CarsouselComponent from './components/carousel/carousel.component'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import FaqComponent from './components/faq/faq.component'
import PlayersRow1Component from './components/players/players-row-1.component'
import PlayersRow2Component from './components/players/players-row-2.component'
import PlayersRow3Component from './components/players/players-row-3.component'

import RainbowKitMint from './components/rainbowkit-mint/rainbowkit-mint.component'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import 'animate.css'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

const Home = () => {
  const navigate = useNavigate()

  const onClickMasterLink = () => {
    const htmlMintValEl = document.getElementById('totalMinted')?.innerHTML ?? '0'
    const totalMinted = parseInt(htmlMintValEl)
    const plural = totalMinted > 1 ? 's' : ''
    navigate('/masterclass')
  }

  return (
    <>
      <nav id="header" className="top-0 ">
        <div className="container max-w-7xl px-3 md:px-6 py-0 mx-auto">
          <div className="relative flex-row md:flex items-center md:justify-start	text-center justify-between">
            <div className="flex flex-col md:flex items-center justify-center md:justify-start	sm:items-stretch sm:justify-start">
              <div className="justify-center md:justify-start	 sm:ml-4 flex-shrink-0 flex items-center text-center">
              <Link to="/">
                  <img className="main_logo relative top-4 relative bottom-1 rounded-lg" src="/sinbinlogo.png" alt="SINBIN NFT" />
                </Link>
              </div>
            </div>

            <div className="flex-col md:flex block md:inline pull-right pr-2 sm:static sm:inset-auto sm:pr-0 mt-8 mb-6 md:mb-0">
              <div className="flex space-x-0 justify-center items-center">
                <div className="container mx-auto">
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-1 mt-6 md:mt-0">
                    <div className="flex justify-center md:justify-end">
                      <a
                        href="https://discord.com/invite/yxr6FgRe7n"
                        target="_blank"
                        className="text-gray-300 bg-emerald-700 hover:bg-emerald-900 hover:text-white px-4 py-4 rounded-md text-sm" rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={brands('telegram')} /> Join SINBIN Discord
                      </a>
                    </div>
                    <div className="flex justify-center">
                      <a
                        href="https://twitter.com/SinBinClub"
                        target="_blank"
                        className="text-gray-300 bg-blue-500 hover:bg-blue-700 hover:text-white px-4 py-4 rounded-md text-sm" rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={brands('twitter')} /> Follow SINBIN Twitter
                      </a>
                    </div>
                    <div className="flex justify-center">
                      <a
                        href="https://byt.io/ethereum/collection/sinbin"
                        target="_blank"
                        className="text-gray-300 px-4 py-4 rounded-md text-sm bg-violet-700 hover:bg-violet-900 hover:text-white" rel="noreferrer"
                      >
                        <img
                          src="https://byt.io/favicon.ico"
                          width="16"
                          height="16"
                          alt="Byt Logo Icon"
                          style={{ display: 'inline', borderRadius: '3px', position: 'relative', top: '-2px' }}
                        />{' '}
                        View SINBIN on Byt
                      </a>
                    </div>
                    <div className="flex justify-center md:justify-self-end md:place-self-end static md:absolute">
                      <ConnectButton.Custom>
                        {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
                          return (
                            <div
                              {...(!mounted && {
                                'aria-hidden': true,
                                style: {
                                  opacity: 0,
                                  pointerEvents: 'none',
                                  userSelect: 'none',
                                },
                              })}
                              className="text-white ml-2 space-x-2 bg-nav-button bg-no-repeat bg-transparent flex items-center bg-contain bg-center p-4 hover:opacity-95 transition-opacity duration-200 ease-in-out"
                            >
                              {(() => {
                                if (!mounted || !account || !chain) {
                                  return (
                                    <button onClick={openConnectModal} type="button" className="uppercase">
                                      <FontAwesomeIcon icon={solid('wallet')} className="hidden"/>
                                      <img src="/logo-sm-white.png" alt="Wallet Icon" className="inline h-6" /> Connect Wallet
                                    </button>
                                  )
                                }

                                if (chain.unsupported) {
                                  return (
                                    <button onClick={openChainModal} type="button" className="uppercase">
                                      Wrong network
                                    </button>
                                  )
                                }

                                return (
                                  <div style={{ display: 'inline' }}>
                                    <button onClick={openChainModal} type="button" className="uppercase">
                                      {chain.hasIcon && (
                                        <div
                                          style={{
                                            background: chain.iconBackground,
                                            width: 12,
                                            height: 12,
                                            borderRadius: 999,
                                            overflow: 'hidden',
                                            marginRight: 4,
                                          }}
                                        >
                                          {chain.iconUrl && (
                                            <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} style={{ width: 12, height: 12 }} />
                                          )}
                                        </div>
                                      )}
                                    </button>

                                    <button onClick={openAccountModal} type="button">
                                      {account.displayName}
                                      {account.displayBalance ? ` (${account.displayBalance})` : ''}
                                    </button>
                                  </div>
                                )
                              })()}
                            </div>
                          )
                        }}
                      </ConnectButton.Custom>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/*  MATRIX HERO W/ HEADER & TEXT */}
      <div className="bg-black hero-div pb-1">
        <div className="container max-w-7xl mx-auto py-6 md:py-0 ">
        <div className="flex-row md:flex mb-0 md:mb-4">
            <div className="flex-col md:flex w-full md:w-1/2 mr-2 justify-center">
            <div className="flex w-full justify-center">
                    <img src="/hero-image.png" className="place-self-center rotate-center hero-img1"/>
                  </div>
            </div>

            <div className="flex-col md:flex w-full md:w-1/2 justify-center">
              <div className="container mx-auto rounded-xl shadow p-4">
                  <div className="animate__animated animate__zoomInDown main-header flex flex-col items-center xl:items-start transparent-container">
                    <h2 className="mb-6 prose text-3xl text-white font-Bebas-Neue-Pro-Regular leading-snug hidden xl:block">
                      <span className="text-primary">The First of its Kind</span>, Hockey NFT That Gives You Access to Professional Players and much more...
                    </h2>
                    <h2 className="mb-6 prose text-3xl lg:text-4xl xl:text-7xl text-white text-center font-Bebas-Neue-Pro-Regular leading-snug block xl:hidden">
                      <span className="text-primary">SINBIN</span>
                      <br />The First of its Kind, Hockey NFT That Gives You Access to Professional Players and much more...
                    </h2> 
                    <div className="flex items-center justify-start space-x-3">
                      <span className="bg-primary w-52 h-1"></span>
                      <span className="bg-white w-10 h-1"></span>
                    </div>

                    <div className="text-base">
                        <h2 className="text-white text-4xl my-4">What is the SINBIN Club?</h2>
                        <p>SINBIN Club is an exclusive, first of its kind, NFT powered community for hockey fans, brought to you by Alterno Management - one of the leading player representation companies in the world!</p>
                        <p className="mt-4">We've partnered with Byt.io to ensure the security and efficiency of the SINBIN Club mint to make sure you are safe and that your transaction goes through smoothly.</p>
                    </div>

                  </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      {/*  HERO IMAGE */}
      <div className="bg-black hero-div2">&nbsp;</div>

      {/*  QUICK STATS SECTION */}
      <section className="container max-w-8xl mx-auto py-6 md:py-0 my-12">
        <div className="flex flex-col justify-center items-center ">
          <h2 className="mb-6 prose text-6xl lg:text-6xl text-white font-Bebas-Neue-Pro-Regular uppercase leading-snug">
            <span className="text-primary">SINBIN CLUB PASS</span> QUICK STATS
          </h2>
          <div className="flex items-center justify-start space-x-3">
            <span className="bg-primary w-52 h-1"></span>
            <span className="bg-white w-10 h-1"></span>
          </div>
        </div>
        <div className="flex flex-wrap justify-center gap-20 lg:gap-16 items-center mt-12">
          <div className="bg-col-card w-48 xl:h-250px px-1 pt-10 pb-16 bg-center bg-100%-100% bg-no-repeat flex flex-col items-center space-y-4 font-Bebas-Neue-Pro-Book">
            <h2 className="text-4xl font-light mr-5">8</h2>
            <span className="text-center mr-5 text-xl">Completely <br/>Unique Mech's </span>
          </div>
          <div className="bg-col-card w-48 xl:h-250px px-2 pt-10 pb-16 bg-center bg-100%-100% bg-no-repeat flex flex-col items-center space-y-4 font-Bebas-Neue-Pro-Book">
            <h2 className="text-4xl font-light mr-5">16</h2>
            <span className="text-center mr-5 text-xl">Upgradeable Game Performance Attributes</span>
          </div>
          <div className="bg-col-card w-48 xl:h-250px px-1 pt-10 pb-16 bg-center bg-100%-100% bg-no-repeat flex flex-col items-center space-y-4 font-Bebas-Neue-Pro-Book">
            <h2 className="text-4xl font-light mr-5">13+</h2>
            <span className="text-center mr-5 text-xl">Different Traits <br/>Per Mech </span>
          </div>
          <div className="bg-col-card w-48 xl:h-250px px-1 pt-10 pb-16 bg-center bg-100%-100% bg-no-repeat flex flex-col items-center space-y-4 font-Bebas-Neue-Pro-Book">
            <h2 className="text-4xl font-light mr-5 text-center flex items-center space-x-2">
              <span>7.6</span>
              <span className="text-2xl">Billion</span>
            </h2>
            <span className="text-center mr-5 text-xl">Possible <br/>Variations </span>
          </div>
          <div className="bg-col-card w-48 xl:h-250px px-1 pt-10 pb-16 bg-center bg-100%-100% bg-no-repeat flex flex-col items-center space-y-4 font-Bebas-Neue-Pro-Book">
            <h2 className="text-4xl font-light mr-5">9,987</h2>
            <span className="text-center mr-5 text-xl">Generative <br/>NFT's </span>
          </div>
          <div className="bg-col-card w-48 xl:h-250px px-1 pt-10 pb-16 bg-center bg-100%-100% bg-no-repeat flex flex-col items-center space-y-4 font-Bebas-Neue-Pro-Book">
            <h2 className="text-4xl font-light mr-5">13</h2>
            <span className="text-center mr-5 text-xl">Legendary <br/>1-Of-1 NFT's </span>
          </div>
        </div>
      </section>


      {/*  CLUB PASS BENEFITS */}
      <section className="relative w-full lg:py-12 pb-16 lg:min-h-1205.87px mt-32">
        <video className="hidden lg:block absolute inset-0 w-full h-full object-cover object-center video-bg"  >
          <source src="https://d3gcjt2ft6rnl5.cloudfront.net/videos/mech-4.mp4" type="video/mp4" />
        </video>
        <div className="lg:hidden w-full bg-mech-section-4-min bg-bottom bg-no-repeat bg-cover min-h-600px"></div>
        <div className="-mt-96 lg:mt-0 container mx-auto flex flex-col justify-center items-center lg:sticky relative bottom-40">
          <h2 className="mb-6 prose text-6xl lg:text-6xl text-white uppercase font-Bebas-Neue-Pro-Regular">SINBIN CLUB PASS <span className="text-primary">BENEFITS</span>
          </h2>
          <div className="flex items-center justify-start space-x-3">
            <span className="bg-primary w-48 lg:w-48 h-1"></span>
            <span className="bg-white w-14 lg:w-14 h-1"></span>
          </div>
        </div>
        <div className="container mx-auto -mt-40 lg:mt-0">
          <div className="mt-20 lg:mt-10">
            <div className="flex flex-wrap justify-center lg:justify-between">
              <div className="flex flex-col space-y-10 mb-10 lg:mb-0">
                <div className="flex flex-col justify-start items-start bg-genesis-card bg-no-repeat bg-100%-100% py-10 px-10 w-full lg:w-394px h-genesis-card">
                  <img src="https://d3gcjt2ft6rnl5.cloudfront.net/img/key.svg" />
                  <h2 className="mt-4 text-2xl font-Bebas-Neue-Pro-Bold uppercase text-primary">ACCESS TO PRO HOCKEY PLAYERS</h2>
                  <p className="mt-3 text-lg max-w-xs text-white">Access to NHL players through digital and in IRL events, prizes, giveaways and much more</p>
                </div>
                <div className="flex flex-col justify-start items-start bg-genesis-card bg-no-repeat bg-100%-100% py-10 px-10 w-full lg:w-394px h-genesis-card">
                  <img src="https://d3gcjt2ft6rnl5.cloudfront.net/img/whitelist.svg"/>
                  <h2 className="mt-4 text-2xl font-Bebas-Neue-Pro-Bold uppercase text-primary">WhiteList Fast track</h2>
                  <p className="mt-3 text-lg max-w-xs text-white">Genesis holders will get priority whitelist access to upcoming SINBIN CLUB PASS NFTS as well as partner NFT collaborations.</p>
                </div>
              </div>
              <div className="flex flex-col space-y-10">
                <div className="flex flex-col justify-start items-start bg-genesis-card bg-no-repeat bg-100%-100% py-10 px-10 w-full lg:w-394px h-genesis-card">
                  <img src="https://d3gcjt2ft6rnl5.cloudfront.net/img/nft-drops.svg"/>
                  <h2 className="mt-4 text-2xl font-Bebas-Neue-Pro-Bold uppercase text-primary">ACCESS SANDBOX METAVERSE</h2>
                  <p className="mt-3 text-lg max-w-xs text-white">Exclusive access to the SINBIN Club House in the Sandbox metaverse.</p>
                </div>
                <div className="flex flex-col justify-start items-start bg-genesis-card bg-no-repeat bg-100%-100% py-10 px-10 w-full lg:w-394px h-genesis-card">
                  <img src="https://d3gcjt2ft6rnl5.cloudfront.net/img/discuss.svg"/>
                  <h2 className="pt-4 text-2xl font-Bebas-Neue-Pro-Bold uppercase text-primary">Exclusive Discord Channel(s)</h2>
                  <p className="mt-3 text-lg max-w-xs text-white">Access to NHL players through digital and in IRL events, prizes, giveaways and much more.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/*  HOCKEY PLAYERS GRID ROW 1 */}
      <PlayersRow1Component />

      {/*  HOCKEY PLAYERS GRID ROW 2 */}
      <PlayersRow2Component />

      {/*  HOCKEY PLAYERS GRID ROW 3 */}
      <PlayersRow3Component />


      {/* FAQ */}
      <FaqComponent />

      {/* HOW IT WORKS */}
      <div className="bg-black hockey-div ">
        <div className="container max-w-9xl px-3 md:px-6  mx-auto">
            <div className="transparent-container2 mx-auto p-2">
                <h1 className="text-white animate__animated animate__bounceInLeft text-4xl font-semibold mb-2 headerTxt">HOW IT WORKS</h1>
                <h4 className="text-white animate__animated animate__boun
                
                ceInLeft text-1xl font-semibold mb-2 headerTxt">Follow us on Instagram for Mint Dates and More Information.</h4>
                <div id="comp-l59v01xk" className="_3_RZn"><div className="_1oCQB"></div></div>
                <ul className="text-white">
                  <li>Mint a SINBIN Club Pass NFT, you immediately become a member of an exclusive hockey community with access to a private portion of SINBIN's Discord Community. Here you can interact with athletes, form friendships, share experiences and get opportunities to win digital and physical/tangible prizes</li>
                  <li>Additionally, your SCP NFT is (randomly) assigned a city and an NHL player. This allows for even more chances to enter raffles for prizes and experiences that are unique to your assigned city/player</li>
                  <li>When SINBIN Sandbox is released, SCP NFT holders will get instant access to their own private clubhouse (based on city/player affiliation) and social center complete with a stage for performances and areas for viewing parties and playing mini-hockey games!</li>
                </ul>

                  <div className="mt-6 flex w-full justify-center">
                  <div className="flex-row md:flex mb-0 md:mb-4 text-center  place-self-center w-full">
                    <a data-testid="linkElement" href="https://www.sinbin.io/join" target="_self" className="place-self-center text-center w-full text-gray-300 bg-emerald-700 hover:bg-emerald-900 hover:text-white px-4 py-4 rounded-md text-sm" aria-label="SIGN UP">
                      SIGN UP
                    </a>
                  </div>
                  </div>
            </div>
        </div>
      </div>
      

      {/* MEDIA */}
      <div className="bg-white">
        <div className="container max-w-7xl px-3 md:px-6 py-5 mx-auto">
            <div className="">
              <h1 className="animate__animated animate__bounceInLeft text-5xl font-semibold mb-2 headerTxt mb-2 headerTxt">Media</h1>
            </div>
            <div className="flex w-full">
                <div className="flex-row md:flex mb-0 md:mb-4  place-self-center w-full">
                  <div className="flex md:flex w-full md:w-1/3 mr-2 justify-center">
                    <img src="./The_Score_Logo.png" className="img1 place-self-center"  />
                  </div>

                  <div className="flex md:flex w-full md:w-1/3 mr-2 align-center justify-center mt-4">
                    <img src="./NHL Network Radio.png" className="img2 place-self-center" />
                  </div>

                  <div className="flex md:flex w-full md:w-1/3 justify-center mt-4">
                    <img src="./dropping the gloves podcast.png" className="img3 place-self-center" />
                  </div>

                </div>
            </div>

          {/* MINT FORM */}
          <div className="flex-row md:flex mb-0 mt-6">
            <div className="flex-col md:flex w-full md:w-1/2 mr-2">
              <img src="/hockey-player2.png" className="rounded-lg" style={{ maxHeight: '350px' }} alt="Hockey Player" />
            </div>

            <div className="flex-col md:flex w-full md:w-1/2">
              <div className="container mx-auto border bg-black rainbowkitMintForm border-white rounded-xl shadow border mt-12 md:mt-0 p-4">
                <RainbowKitMint />
              </div>
            </div>
          </div>


        </div>
      </div>




    {/* FOOTER */}
    <div id="comp-l59v01xk" className="_3_RZn"><div className="_1oCQB"></div></div>
    <footer className="bg-black pb-5">
        <div className="max-w-screen-xl px-4 pt-8 mx-auto sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="flex justify-center text-teal-300 sm:justify-start">
          <     img className="rounded-full" src="./logo-sm.png" width="40" height="40" />
            </div>

            <p className="mt-4 text-sm text-center text-gray-400 lg:text-right lg:mt-0">
              T&C &nbsp; Career &nbsp; Privacy & Policy &nbsp; Developers
            </p>
          </div>
        </div>
    </footer>


    </>
  )
}

const NotFound = () => {
  return (
    <>
      <div>404 page not found.</div>
    </>
  )
}

export default App
