import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty

function PlayersRow1() {
  return (
        <section className="players_row_1">
        <div  className="_3wnIc">
          <div className="_3KzuS _3SQN-"></div>
          <div className="_2GUhU"></div>
        </div>
        <div className="_1uldx">
          <div className="_1vNJf">
            <div className="_3wnIc">
              <div className="_3KzuS _3SQN-"></div>
              <div className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_e4baab349cec491db17fac8e803c3afc~mv2.jpeg/v1/fill/w_960,h_1155,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cf6500_e4baab349cec491db17fac8e803c3afc~mv2.jpeg" alt="Victor Hedman For Site.jpeg" className="player-img" />
              </div>
            </div>
            <div className="player player1">
              <div className="_2Hij5 _3bcaz">
                <p className="font_0">
                  <span className="player-grid-span">
                    <span className="font25">
                      <span className="spacingNormal">
                        <span style={{color: '#FFFFFF'}}>Victor Hedman</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
              <div className="_2Hij5 _3bcaz">
                <p className="font_0">
                  <span className="spacingNormal">
                    <span style={{color:'#FFFFFF'}}>
                      <span style={{fontSize:'17px'}}>
                        <span className="font3">TAMPA BAY</span>
                      </span>
                    </span>
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div id="comp-l7jxxnnl" className="_1vNJf">
            <div className="_3wnIc">
              <div className="_3KzuS _3SQN-"></div>
              <div className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_5fd0ebddfabf4b37a381f184747a33e9~mv2.jpg/v1/fill/w_960,h_1155,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cf6500_5fd0ebddfabf4b37a381f184747a33e9~mv2.jpg" alt="Liljegren-1392853681.jpg" className="player-img"/>
              </div>
            </div>
            <div className="player player2">
                <div>
                  <div className="_2Hij5 _3bcaz">
                    <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                      <span className="font4">
                        <span className="font25">
                        <span className="spacingNormal">
                          <span style={{color:'#FFFFFF'}}>Timothy Liljegren</span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div className="_2Hij5 _3bcaz" >
                    <p className="font_1">
                    <span className="spacingNormal">
                        <span style={{color:'#FFFFFF'}}>
                          <span className="font_1">
                            <span className="font5">TORONTO</span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
          </div>
          <div className="_1vNJf">
            <div className="_3wnIc">
              <div className="_3KzuS _3SQN-"></div>
              <div className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_b4b4a8931d434a999b0111f2c6111517~mv2.jpg/v1/fill/w_475,h_572,al_c,lg_1,q_80,enc_auto/cf6500_b4b4a8931d434a999b0111f2c6111517~mv2.jpg" alt="Pettersson-1375093648-594x594.jpg" className="player-img"/>
              </div>
            </div>
            <div className="player player3">
                <div>
                  <div>
                  <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                      <span className="font9">
                        <span className="font25">
                          <span className="spacingNormal">
                          <span style={{color:'#FFFFFF'}}>Marcus Pettersson</span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                  <div className="_2Hij5 _3bcaz">
                  <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                      <span className="spacingNormal">
                        <span style={{color:'#FFFFFF'}}>
                          <span className="font_1">
                            <span className="font7">PITTSBURGH</span>
                          </span>
                        </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
          </div>
          <div className="_1vNJf">
            <div className="_3wnIc">
              <div className="_3KzuS _3SQN-"></div>
              <div className="_2GUhU">
                  <img src="https://static.wixstatic.com/media/cf6500_18f668ec17814f919c4dc7fa42f5f101~mv2.jpg/v1/fill/w_960,h_1155,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cf6500_18f668ec17814f919c4dc7fa42f5f101~mv2.jpg" alt="" className="player-img"/>
              </div>
            </div>
            <div className="player player4">
              <div>
                <div className="_2Hij5 _3bcaz" >
                <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                    <span className="font8">
                      <span className="font25">
                        <span className="spacingNormal">
                          <span style={{color:'#FFFFFF'}}>Jesper Boqvist</span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
                <div className="_2Hij5 _3bcaz">
                  <p className="font_0" style={{fontSize: '25px', lineHeight:'normal'}}>
                    <span className="spacingNormal">
                      <span style={{color:'#FFFFFF'}}>
                        <span style={{fontSize:'17px'}}>
                          <span className="font10">NEW JERSEY</span>
                        </span>
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  )
}

export default PlayersRow1
